<template>
	<div class="CirleDetailPraise">
		<w-navTab titleText="点赞详情"></w-navTab>
		<div class="container">
		<div class="CirleDetailPraise-title">点赞情况</div>
		<div class="CirleDetailPraise-list">
			<w-list class="list-view sign-in-scroll" :loading="loading" :finished="finished" :is-empty="isEmpty" @onLoad="onLoad">
			<div class="list-item" v-for="item in starList">
				<div class="list-item-left">
					<div class="list-item-left-picture">
						<img :src="item.avatar" alt="">
						<div class="list-item-left-picture-mark">
							<img src="../../assets/img/icon420.png" mode="" v-if="item.sex == 2"></img>
							<img src="../../assets/img/icon421.png" mode="" v-else></img>
						</div>
					</div>
					<div class="list-item-left-body">
						<div class="list-item-left-body-name">{{item.name}}</div>
						<div class="list-item-left-body-text">{{item.level_name}}</div>
					</div>
				</div>
				<div class="list-item-right">已点赞</div>
			</div>
			</w-list>
		</div>
		<div class="CirleDetailPraise-footer">
			共{{total}}人点赞
		</div>
		</div>
	</div>
</template>

<script>
import Circle from '@/api/circle';
import Vant from '@/vendor/vant';
	export default{
		name:"CirleDetailPraise",
		data() {
			return{
				page: 1,
				limit: 10,
				total: '',
				momentId: '',
				starList: [],
				loading: true,
				finished: false,
				isEmpty: false,
			}
		},
		created() {
			this.momentId = this.$route.params.id
			this.onLoad()
		},
		methods:{
			onLoad(){
				let params = {
					page: this.page,
					limit: this.limit,
					moment_id: this.momentId
				}
				Circle.starLists(params)
					.then(result=>{
						this.total = result.data.total
						this.starList = this.starList.concat(result.data.lists);
						this.page++
						this.loading = false;
						
						if (this.starList.length >= result.data.total) {
							this.finished = true;
						}
						
						this.isEmpty = this.starList.length <= 0;
					},error=>{
						Vant.Toast.fail(error.msg)
					})
			}
		}
	}
</script>

<style scoped lang="less">
	.CirleDetailPraise{
		background-color: #fff;
		.container{
			padding: 0 8px;
			box-sizing: border-box;
			.CirleDetailPraise-title{
				font-weight: bold;
				font-size: 17px;
				line-height: 20px;
				color: #1C1D1D;
				padding: 12px 0;
			}
			.CirleDetailPraise-list{
				.list-item:first-child{
					border-top: 1px solid #F5F6F8;
				}
				.list-item{
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 12px 0;
					border-bottom: 1px solid #F5F6F8;
					.list-item-left{
						display: flex;
						align-items: center;
						.list-item-left-picture{
							width: 40px;
							height: 40px;
							border-radius: 50%;
							position: relative;
							img{
								width: 100%;
								height: 100%;
								border-radius: 50%;
								object-fit: cover;
							}
							.list-item-left-picture-mark{
								width: 22px;
								height: 22px;
								box-shadow: 0 0 2px rgba(0, 0, 0, 0.05);
								border-radius: 100px;
								position: absolute;
								bottom: -3px;
								right: -4px;
								z-index: 10;
								img{
									width: 100%;
									height: 100%;
									border-radius: 100px;
									object-fit: cover;
								}
							}
						}
						.list-item-left-body{
							padding-left: 4px;
							.list-item-left-body-name{
								font-weight: bold;
								font-size: 15px;
								line-height: 22px;
								color: #1C1D1D;
							}
							.list-item-left-body-text{
								font-size: 12px;
								line-height: 17px;
								color: #44484E;
								margin-top: 1px;
							}
						}
					}
					.list-item-right{
						font-size: 12px;
						line-height: 17px;
						text-align: right;
						color: #44484E;
					}
				}
			}
			.CirleDetailPraise-footer{
				position: fixed;
				left: 50%;
				transform: translateX(-50%);
				bottom: 20px;
				font-size: 15px;
				line-height: 22px;
				color: #777B81;
			}
		}
		
	}
</style>